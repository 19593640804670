import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { PendingSessionCard } from "./PendingSessionCard";
import { SessionDetailCard } from "./SessionDetailCard";
import moment from "moment";
import {
  generateToken,
  getMedicalRecords,
  getReports,
  getSessionById,
  joinSession,
  postDuration,
  updateSession,
} from "../../../../api/services/api";
import Loader from "../../../../components/Loader";
import { VideoCall } from "../../VideoCall";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import axios from "axios";
import { http } from "../../../../api/http";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../../components/DrawerTemplate";
import { GenerateReportForm } from "./GenerateReportForm";

export const SessionView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [filterData, setFilterData] = useState(id);
  const [token, setToken] = useState(null);
  const [channel, setChannel] = useState(null);
  const [startSession, setStartSession] = useState(false);
  const [patientProfileId, setpatientProfileId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [checkJoinSession, setCheckJoinSession] = useState(false);
  const [currentTime, setcurrentTime] = useState(
    moment().format("YYYY-MM-DD HH:mm")
  );
  const [joinResponse, setJoinResponse] = useState<any>();
  const [homeSession, setHomeSession] = useState(false);
  const [checkOtp, setCheckOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const queryClient = useQueryClient();
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [userJoin, setUserJoin] = useState(false);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const { data, isLoading } = useQuery(
    ["sessions", filterData],
    getSessionById
  );

  const { data: getMedicalRecordsData } = useQuery(
    [
      "medical-record",
      {
        patientProfileId: patientProfileId,
      },
    ],
    getMedicalRecords,
    {
      enabled: Boolean(patientProfileId),
    }
  );

  const { data: joinsessionData } = useQuery(
    [
      "join-session",
      {
        id: id,
        time: currentTime,
      },
    ],
    joinSession,
    {
      onSuccess: (res) => {
        setJoinResponse(res?.data);
      },
      enabled: Boolean(checkJoinSession),
    }
  );

  const { mutate } = useMutation(generateToken, {
    onSuccess: (res) => {
      setToken(res?.data?.token);
      setStartSession(true);
    },
  });

  const handleReports = (id: any) => {
    setpatientProfileId(id);
    setOpenDialog(true);
  };
  let mode =
    data?.data?.consultationMode.slice(0, 1).toUpperCase() +
    data?.data?.consultationMode.slice(1).toLowerCase();

  const { mutate: statusMutate } = useMutation(updateSession, {
    onSuccess: () => {
      queryClient.invalidateQueries("sessions");
    },
  });

  useEffect(() => {
    setCheckJoinSession(true);
  }, []);

  const startSessionClick = (SessionId: any, clinicianId: any, status: any) => {
    const res = { channel: SessionId, userId: clinicianId };
    console.log(res,"res");
    if (joinResponse) {
      if (data?.data?.consultationMode === "HOME") {
        setHomeSession(true);
      } else {
        mutate(res);

        setChannel(SessionId);
      }
      const statusRes = { status: "STARTED" };
      statusMutate({ id: id, body: statusRes });
    } else {
      toast.error("Error");
    }
  };

  const handleComplete = () => {
    const statusRes = { status: "COMPLETED" };
    statusMutate({ id: id, body: statusRes });
    setHomeSession(false);
    if (userJoin === true) {
      newFunc();
    }
  };

  const newFunc = () => {
    const res = {
      sessionId: +id!,
      duration: Math.floor(totalSeconds / 60),
      userType: "CLINICIAN",
    };
    newDuration(res);
  };

  const handlePdfChange = async (event: any, id: any) => {
    let fileKey = [];
    const file = event.target.files;
    console.log(file,"file");
    for (let key in file) {
      const formData = new FormData();
      formData.append("file", file[key]);
      formData.append("sessionId", id);
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/reports/upload`,
          data: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}` || "",
          },
        });
        fileKey.push(response?.data?.key);
        queryClient.invalidateQueries("sessions");
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const data = {
        sessionId: id,
        type: "UPLOAD",
        fileKeys: [...fileKey],
      };
      http
        .post("reports/save", data)
        .then((res) => toast.success(res?.data?.message));
      queryClient.invalidateQueries("sessions");
    } catch (error) {
      console.log(error);
    }
  };

  const generateReportClick = () => {
    setOpen(true);
  };

  const getEndTime = (data?.data?.date + " " + data?.data?.endAt).toString();

  const [minutes, setMinutes] = useState<any>("00");
  const [seconds, setSeconds] = useState("00");

  let interval: any = useRef();

  const start = () => {
    interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const endTime = new Date(getEndTime).getTime();
      const difference = endTime - currentTime;

      const minute: any = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const second: any = Math.floor((difference % (1000 * 60)) / 1000);

      if (difference < 0) {
        clearInterval(interval.current);
      } else {
        setMinutes(minute);
        setSeconds(second);
      }
    }, 1000);
  };

  useEffect(() => {
    start();
    return () => {
      clearInterval(interval.current);
    };
  });

  const [progress, setProgress] = useState(100);

  const calculation = () => {
    const remain = 60 - minutes;
    const divide = remain / 60;
    const subtract = 1 - divide;
    const finalTime = Math.floor(subtract * 100);
    return finalTime;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(calculation());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const { mutate: newDuration } = useMutation(postDuration);

  const handleOtp = () => {
    if (data?.data?.otp === +otp) {
      setCheckOtp(true);
      toast.success("OTP verified successfully");
      setUserJoin(true);
    } else {
      setCheckOtp(false);
      toast.error("OTP not verified!");
    }
  };

  useEffect(() => {
    if (userJoin === true) {
      const timer = setTimeout(() => {
        setTotalSeconds(totalSeconds + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  return (
    <Box>
      {startSession === false ? (
        <>
          <Box display="flex" gap={1} alignItems="center">
            <ArrowBackIcon
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/sessions")}
            />
            <Typography variant="h3">Session Details</Typography>
          </Box>

          <Grid my="10px" container spacing={2}>
            <Grid item lg={4} md={5}>
              <Card sx={{ pt: "25px" }}>
                <Typography pb={2} px={2} variant="h4">
                  Other Sessions
                </Typography>
                <Divider />
                <Box
                  p={2}
                  overflow="auto"
                  maxHeight="700px"
                  className="scrollBox"
                >
                  <PendingSessionCard
                    filterData={id}
                    setFilterData={setFilterData}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item lg={6} md={7}>
              <Card sx={{ pt: "25px" }}>
                <Typography pb={2} px={2} variant="h4">
                  Sessions Details
                </Typography>
                <Divider />
                {isLoading ? (
                  <Loader />
                ) : (
                  <SessionDetailCard
                    key={id}
                    checkOtp={checkOtp}
                    joinHomeSession={homeSession}
                    otpDate={moment(data?.data?.date).format("DD MMM, YYYY")}
                    minutes={minutes}
                    seconds={seconds}
                    progress={progress}
                    handleOtpClick={handleOtp}
                    otpChange={(e: any) => setOtp(e)}
                    otpValue={otp}
                    patientView={false}
                    statusData={data}
                    sessionId={data?.data?.sessionId}
                    speciality={data?.data?.clinician?.designation}
                    modeOfConsultation={mode}
                    bookedDate={moment(data?.data?.createdAt).format(
                      "DD-MM-YYYY"
                    )}
                    sessionDate={moment(data?.data?.date).format("DD")}
                    sessionMonth={moment(data?.data?.date).format("MMM")}
                    startTime={data?.data?.startAt}
                    endTime={data?.data?.endAt}
                    timeSlots={data?.data?.sessionTimeslots}
                    description={data?.data?.description || "N/A"}
                    doctorImage={data?.data?.clinician?.imageUrl}
                    doctorName={data?.data?.clinician?.user?.fullName}
                    doctorExperience={data?.data?.clinician?.experience}
                    doctorSpeciality={data?.data?.clinician?.designation}
                    patientImage={data?.data?.patient?.imageUrl}
                    patientName={data?.data?.patient?.user?.fullName}
                    patientNumber={data?.data?.patient?.user?.mobileNumber}
                    patientEmail={data?.data?.patient?.user?.email || "N/A"}
                    patientProfileImage={data?.data?.patientProfile?.imageUrl}
                    PatientProfileName={data?.data?.patientProfile?.fullName}
                    PatientProfileAge={data?.data?.patientProfile?.age}
                    PatientProfileGender={data?.data?.patientProfile?.gender}
                    PatientLandmark={data?.data?.patientAddress?.landmark}
                    patientAddress1={
                      data?.data?.patientAddress?.addressLine1 || "N/A"
                    }
                    patientAddress2={
                      data?.data?.patientAddress?.addressLine2 || "N/A"
                    }
                    PatientCity={data?.data?.patientAddress?.city}
                    consultationFee={data?.data?.consultationFee}
                    startSessionClick={() =>
                      startSessionClick(
                        data?.data?.sessionId,
                        data?.data?.clinician?.id,
                        data?.data?.status
                      )
                    }
                    handleReportClick={() =>
                      handleReports(data?.data?.patientProfile?.id)
                    }
                    handleComplete={handleComplete}
                    handlePdfChange={(e: any) =>
                      handlePdfChange(e, data?.data?.id)
                    }
                    generateReportClick={generateReportClick}
                    handleReportsView={() => {
                      navigate(`/reports/${data?.data?.patientProfile?.id}`);
                    }}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle sx={{ fontSize: "20px" }}>Medical Records</DialogTitle>
            <DialogContent sx={{ maxWidth: "550px" }} dividers>
              <Box width="100%" height="100%">
                {getMedicalRecordsData?.data?.data?.length === 0 ? (
                  <Typography variant="h5" sx={{ opacity: "0.7" }}>
                    No Medical Record Found...{" "}
                  </Typography>
                ) : (
                  <>
                    {getMedicalRecordsData?.data?.data?.map(
                      (reports: any, index: number) => (
                        <>
                          <Box>
                            <Box maxHeight="500px">
                              <List sx={{ width: "500px", px: 1 }} key={index}>
                                <ListItem
                                  secondaryAction={
                                    <Box>
                                      <a
                                        href={reports?.fileUrl}
                                        download
                                        style={{ textDecoration: "none" }}
                                      >
                                        <Button variant="outlined">
                                          <DownloadIcon sx={{ pr: 1 }} />
                                          Download Report
                                        </Button>
                                      </a>
                                    </Box>
                                  }
                                >
                                  <Box
                                    sx={{
                                      backgroundColor:
                                        theme.colors.primary.lighter,
                                      p: 1.5,
                                      borderRadius: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      mr: 1,
                                    }}
                                  >
                                    <Icon
                                      sx={{ color: theme.colors.primary.main }}
                                    >
                                      <DescriptionOutlinedIcon />
                                    </Icon>
                                  </Box>
                                  <ListItemText>
                                    Report {index + 1}
                                  </ListItemText>
                                </ListItem>
                              </List>
                            </Box>
                          </Box>
                        </>
                      )
                    )}
                  </>
                )}
              </Box>
            </DialogContent>
          </Dialog>
          <Drawer
            anchor="right"
            open={open}
            onClose={() => toggleDrawer(false)}
          >
            <StyledDrawerHeader>
              <Typography>Generate Manual Report</Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "Inter_semibold",
                  color: "black",
                  opacity: "0.5",
                  cursor: "pointer",
                }}
                onClick={() => toggleDrawer(false)}
              >
                Cancel
              </Typography>
            </StyledDrawerHeader>
            <StyledDrawerContent>
              <GenerateReportForm
                toggleDrawer={(e: any) => toggleDrawer(e)}
                data={data?.data}
              />
            </StyledDrawerContent>
          </Drawer>
        </>
      ) : (
        <Box>
          <VideoCall
            token={token}
            setToken={setToken}
            channel={channel}
            setChannel={setChannel}
          />
        </Box>
      )}
    </Box>
  );
};
