import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getPatientById, getReports } from "../../../api/services/api";
import textCapitalization from "../../../components/textCapitalization";

function PatientProfile() {
  const theme = useTheme();
  const patientId = useParams();
  const [patientProfileId, setpatientProfileId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ["patient", patientId?.id],
    getPatientById
  );

  const { data: getReportData } = useQuery(
    [
      "reports-id",
      {
        patientProfileId: patientProfileId,
      },
    ],
    getReports,
    {
      enabled: Boolean(patientProfileId),
    }
  );

  const handleReports = (id: any) => {
    setpatientProfileId(id);
    setOpenDialog(true);
  };

  return (
    <Box>
      <Box mt={3}>
        <Box display="flex" gap={1} mb={2} alignItems="center">
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/patients")}
          />
          <Typography variant="h3">Client Details</Typography>
        </Box>

        <Card sx={{ mb: 2 }}>
          <Box display="flex" p="25px" alignItems="center">
            <Avatar
              variant="rounded"
              sx={{ height: "142px", width: "142px", fontSize: "100px" }}
              src={data?.data?.imageUrl}
              alt={data?.data?.fullName}
            />
            <Box pl={3} flex={1}>
              <NameBox title="Name" detail={data?.data?.fullName} />
            </Box>
            <Box flex={1} display="flex">
              <Box
                borderLeft="1px solid"
                mr={3}
                sx={{ opacity: "0.1" }}
                height="45px"
              />
              <NameBox
                title="Gender"
                detail={textCapitalization(data?.data?.gender || "")}
              />
            </Box>
            <Box flex={1} display="flex">
              <Box
                borderLeft="1px solid"
                mr={3}
                sx={{ opacity: "0.1" }}
                height="45px"
              />
              <NameBox title="Age" detail={data?.data?.age || "NA"} />
            </Box>
            <Box flex={1.3} display="flex">
              <Box
                borderLeft="1px solid"
                mr={3}
                sx={{ opacity: "0.1" }}
                height="45px"
              />
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => handleReports(data?.data?.id)}
                >
                  Medical Reports
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ fontSize: "20px" }}>Reports</DialogTitle>
        <DialogContent sx={{ maxWidth: "550px" }} dividers>
          <Box width="100%" height="100%">
            {getReportData?.data?.count === 0 ? (
              <Typography variant="h5" sx={{ opacity: "0.7" }}>
                No Reports Found...{" "}
              </Typography>
            ) : (
              <>
                {getReportData?.data?.sessions?.map((reports: any) => (
                  <>
                    {reports?.reports?.map((report: any, index: number) => (
                      <Box>
                        <Box maxHeight="500px">
                          <List sx={{ width: "500px", px: 1 }} key={index}>
                            <ListItem
                              secondaryAction={
                                <Box>
                                  <a
                                    href={report?.fileUrl}
                                    download
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button variant="outlined">
                                      <DownloadIcon sx={{ pr: 1 }} />
                                      Download Report
                                    </Button>
                                  </a>
                                </Box>
                              }
                            >
                              <Box
                                sx={{
                                  backgroundColor: theme.colors.primary.lighter,
                                  p: 1.5,
                                  borderRadius: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  mr: 1,
                                }}
                              >
                                <Icon sx={{ color: theme.colors.primary.main }}>
                                  <DescriptionOutlinedIcon />
                                </Icon>
                              </Box>
                              <ListItemText>Report {report.id}</ListItemText>
                            </ListItem>
                          </List>
                        </Box>
                      </Box>
                    ))}
                  </>
                ))}
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default PatientProfile;

const NameBox = ({ title, detail }: any) => {
  return (
    <Box>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {detail}
      </Typography>
    </Box>
  );
};
