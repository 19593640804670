import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ConfirmDialogProvider from "../components/ConfirmDialogProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AdminRoutesContainer from "./AdminRoutesContainer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <ConfirmDialogProvider>
            <AdminRoutesContainer />
          </ConfirmDialogProvider>
        </QueryClientProvider>
      </LocalizationProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
