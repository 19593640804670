import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import _without from "lodash/without";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getAllServices,
  updateClinicianProfile,
} from "../../../api/services/api";
import ImageUpload from "../../../components/ImageUpload";

export const EditProfileForm = ({ prevData, toggleDrawer }: any) => {
  const queryClient = useQueryClient();
  const [dob, setDob] = useState<any>(moment(new Date(prevData?.dob)));
  const [picDetails, setPicDetails] = useState<any>(null);
  const [editImage, setEditImage] = useState(prevData?.imageUrl);
  const [selecteServices, setSelecteServices] = useState<any>(
    prevData?.services ? prevData?.services : []
  );

  const currentYear = moment().format("YYYY");
  const dobYear = moment(dob).format("YYYY");
  const [currentAge, setcurrentAge] = useState<any>(+currentYear - +dobYear);

  const { data: serviceData } = useQuery("services", getAllServices);

  const handleChangeSymptom = (e: any) => {
    setSelecteServices(e.target.value);
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setSelecteServices((current: any) => _without(current, value));
  };

  const { mutate } = useMutation(updateClinicianProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("clinician-profile");
      toggleDrawer(false);
      toast.success("Profile Updated Successfully");
    },
    onError: (res: any) => {
      {
        res?.response?.data?.message?.map((item: any) => toast.error(item));
      }
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      fullName: prevData?.user?.fullName,
      mobileNumber: prevData?.user?.mobileNumber,
      // alternateMobileNumber: prevData?.alternateMobileNumber || "",
      gender: prevData?.gender,
      dob: dob,
      email: prevData?.user?.email,
      // alternateEmail: prevData?.alternateEmail || "",
      age: currentAge,
      bio: prevData?.bio,
    },
  });

  const onSubmit = (data: any) => {
    const res = {
      ...data,
      key: picDetails?.key,
      dob: moment(dob).format("YYYY-MM-DD"),
      age: currentAge,
      serviceIds: selecteServices?.map((item: any) => item?.id),
    };
    mutate({ body: res, id: prevData?.id });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box width="550px" display="flex" flexDirection="column" gap={3}>
          <ImageUpload
            picDetails={picDetails}
            setPicDetails={setPicDetails}
            editImage={editImage}
            setEditImage={setEditImage}
            title={"photo"}
          />
          <TextField
            label="Name"
            variant={"standard"}
            fullWidth={true}
            {...register("fullName", { required: true })}
          />
          <TextField
            label="Mobile Number"
            variant={"standard"}
            disabled
            fullWidth={true}
            {...register("mobileNumber", {
              required: true,
              pattern: /^\d{10}$/,
            })}
          />
          <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">Gender *</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="MALE"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="FEMALE"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="OTHERS"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth={true}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <DatePicker
                  label="Date of Birth"
                  inputFormat="DD/MM/YYYY"
                  value={dob}
                  {...register("dob", { required: true })}
                  maxDate={moment(new Date())}
                  onChange={(e: any) => {
                    setDob(moment(new Date(e)));
                    setcurrentAge(+currentYear - +dobYear);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      variant={"standard"}
                      fullWidth={true}
                      required={true}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </FormControl>
          <TextField
            type={"number"}
            value={currentAge}
            defaultValue={currentAge}
            inputProps={{ min: 0 }}
            {...register("age", {
              // required: edit ? false : true,
            })}
            label={"Age "}
            required={true}
            variant={"standard"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Year</InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth>
            <FormLabel component="legend">Services *</FormLabel>
            <Select
              fullWidth
              multiple
              required
              variant="standard"
              value={selecteServices}
              onChange={handleChangeSymptom}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: any) => (
                    <Chip
                      key={value?.id}
                      clickable
                      deleteIcon={
                        <CancelSharpIcon
                          onMouseDown={(event: any) => event.stopPropagation()}
                        />
                      }
                      label={value?.title}
                      onDelete={(e: any) => handleDelete(e, value)}
                    />
                  ))}
                </Box>
              )}
            >
              {serviceData?.data?.services?.map((item: any, index: number) => (
                <MenuItem key={index} value={item}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Email Address"
            variant={"standard"}
            disabled
            fullWidth={true}
            {...register("email", {
              required: true,
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            })}
          />
          <TextField
            label="Bio"
            variant={"standard"}
            fullWidth={true}
            multiline
            rows={2}
            {...register("bio", { required: true })}
          />
        </Box>
        <Button
          fullWidth={true}
          type="submit"
          variant="contained"
          sx={{ marginTop: "32px", p: 2 }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};
