import {
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getClinicianProfile,
  updateClinicianProfile,
} from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import textCapitalization from "../../../components/textCapitalization";
import { EditProfileForm } from "./EditProfileForm";
import { SideProfileInfo } from "./SideProfileInfo";

export const PaymentDetails = () => {
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [packageDetail, setPackageDetail] = useState(false);

  const { data, isLoading } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  let item = data?.data;

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (item) {
      setValue("bankName", item.bankName || "");
      setValue("bankCode", item.bankCode || "");
      setValue("bankAccountNumber", item.bankAccountNumber || "");
    }
  }, [item, setValue]);

  const handleClick = () => {
    setPackageDetail(true);
    setOpen(true);
  };

  const handleProfileClick = () => {
    setPackageDetail(false);
    setOpen(true);
  };

  const { mutate } = useMutation(updateClinicianProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("clinician-profile");
      toggleDrawer(false);
      toast.success("Profile Updated Successfully");
    },
    onError: (res: any) => {
      {
        res?.response?.data?.message?.map((item: any) => toast.error(item));
      }
    },
  });

  const onSubmit = (formData: any) => {
    const updatedData = {
      ...item,
      ...formData,
    };
    mutate({ body: updatedData, id: item?.id });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Card>
            <Box
              py={1}
              px={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontSize: "20px" }}>
                Payment Details
              </Typography>
              <Button onClick={handleClick} variant="outlined">
                Edit
              </Button>
            </Box>
            <Divider />
            <Box pt={2} px={4} display="flex" justifyContent="space-between">
              <NameBox
                title="Bank Name"
                detail={item?.bankName || "UAE Bank"}
              />
              <NameBox title="Code" detail={item?.bankCode || "UAE0000012"} />
            </Box>
            <Box p={1} px={4}>
              <NameBox
                title="Account Number"
                detail={item?.bankAccountNumber || "123456789"}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <SideProfileInfo
            img={item?.imageUrl}
            name={item?.user?.fullName}
            location={item?.location}
            onClick={() => setOpen(true)}
            designation={item?.designation}
            number={item?.user?.mobileNumber}
            email={item?.user?.email}
            experience={item?.experience}
            dob={moment(item?.dob).format("DD-MM-YYYY")}
            gender={textCapitalization(item?.gender || "")}
            languages={item?.languagesKnown}
            services={item?.services}
          />
        </Grid>
      </Grid>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography>
            {packageDetail ? "Payment Details" : "Edit Profile"}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          {packageDetail ? (
            <Box
              component="form"
              width="500px"
              display="flex"
              flexDirection="column"
              gap={3}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="bankName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Bank Name"
                    variant={"standard"}
                    fullWidth={true}
                  />
                )}
              />
              <Controller
                name="bankCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Code"
                    variant={"standard"}
                    fullWidth={true}
                  />
                )}
              />
              <Controller
                name="bankAccountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Account Number"
                    variant={"standard"}
                    fullWidth={true}
                  />
                )}
              />
              <Button sx={{ mt: 2, p: 2 }} variant="contained" type="submit">
                Save Changes
              </Button>
            </Box>
          ) : (
            <EditProfileForm
              prevData={item}
              toggleDrawer={(e: any) => toggleDrawer(e)}
            />
          )}
        </StyledDrawerContent>
      </Drawer>
    </Box>
  );
};

const NameBox = ({ title, detail }: any) => {
  return (
    <Box pb={2}>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {detail}
      </Typography>
    </Box>
  );
};
