import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getSessionById } from "../api/services/api";

export const Timer = (sessionUrl: any) => {
  const [getEndDateTime, setGetEndDateTime] = useState<any>();

  const { data, isLoading } = useQuery(
    ["sessionDetail", sessionUrl?.sessionUrl],
    getSessionById,
    {
      enabled: !!sessionUrl?.sessionUrl,
    }
  );

  useEffect(() => {
    const endTime: any = new Date(
      (data?.data?.date + " " + data?.data?.endAt)?.toString()
    ).getTime();
    setGetEndDateTime(endTime);
  }, [data]);

  const [difference, setDifference] = useState<any>("");

  useEffect(() => {
    let time = setTimeout(() => {
      const currentTime = new Date().getTime();
      const difference: any = getEndDateTime - currentTime;
      setDifference(difference);
    }, 1000);
    return () => clearTimeout(time);
  });

  return (
    <Box
      mt={2}
      maxWidth="95px"
      borderRadius="10px"
      bgcolor={"#D58E4E"}
      px={3}
      py={1}
      display="flex"
    >
      <Typography>
        {Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))}
      </Typography>
      <Typography>:</Typography>
      <Typography>{Math.floor((difference % (1000 * 60)) / 1000)}</Typography>
    </Box>
  );
};
