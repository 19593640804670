//@ts-nocheck
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { icons } from "../assets";
import Loader from "./Loader";

const ImageUpload = ({
  picDetails,
  setPicDetails,
  editImage,
  setEditImage,
  title,
}: any) => {
  const [pic, setPic] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleChange(e: any) {
    e.preventDefault();
    console.log(e?.target?.files[0]);
    setPic(e?.target?.files[0]);
  }

  useEffect(() => {
    if (pic != null) {
      onSubmit();
    }
  }, [pic]);

  const onSubmit = async () => {
    // event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    console.log(pic);
    formData.append("file", pic);
    try {
      const response = await axios({
        method: "post",
        url: "https://api-alsan.7t6.in/common/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setPicDetails(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(pic);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      {" "}
      {!editImage ? (
        !picDetails ? (
          <>
            <label htmlFor={"photo"}>
              <Box
                borderRadius={8}
                textAlign="center"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "15px",
                  marginTop: "25px",
                }}
                py={2}
                width="140px"
                height="110px"
                border="2px solid #F5F5F5;"
                gap={1}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  gap={"5px"}
                >
                  <img
                    src={icons.ProfileAvatar}
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "Inter_regular",
                      opacity: "0.5",
                    }}
                  >
                    {`Upload ${title}`}
                  </Typography>
                </Box>
              </Box>
            </label>
            <input
              onChange={(e) => handleChange(e)}
              type="file"
              style={{ display: "none" }}
              id={"photo"}
              accept=".jpg,.png"
            />
          </>
        ) : (
          <Box sx={{ display: "flex" }} gap={"5px"}>
            <img
              src={picDetails?.Location}
              style={{
                width: "140px",
                height: "120px",
                border: "2px solid #F5F5F5;",
                borderRadius: "15px",
                marginTop: "25px",
              }}
            />
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                sx={{ height: "30px", backgroundColor: "red" }}
                variant="contained"
                onClick={() => setPicDetails(null)}
              >
                Delete
              </Button>
            </Box>
          </Box>
        )
      ) : (
        <Box sx={{ display: "flex" }} gap={"5px"}>
          <img
            src={editImage}
            style={{
              width: "140px",
              height: "120px",
              border: "2px solid #F5F5F5;",
              borderRadius: "15px",
              marginTop: "25px",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Button
              sx={{ height: "30px", backgroundColor: "red" }}
              variant="contained"
              onClick={() => setEditImage(null)}
            >
              Delete
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ImageUpload;
