import { Box, Button, Grid, useTheme } from "@mui/material";
import { useState } from "react";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SummarizeIcon from "@mui/icons-material/Summarize";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useQuery } from "react-query";
import {
  getClinicianProfile,
  getPayoutStatistics,
} from "../../../api/services/api";
import AnalyticsCard from "../../../components/AnalyticsCard";
import { PayoutsList } from "./PayoutsList";
import { SessionPayments } from "./SessionPayments";

function Payments() {
  const theme = useTheme();
  const [showPayments, setshowPayments] = useState(true);

  const { data: clinicianData } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  const { data, isLoading } = useQuery(
    ["payoutsStats", clinicianData?.data?.id],
    getPayoutStatistics,
    {
      enabled: Boolean(clinicianData?.data?.id),
    }
  );

  const customStyles = {
    background1: "rgba(128, 128, 128, 0.6)",
    background2: "rgba(204, 119, 34, 0.6)",
    borderRadius: "18px 18px 0px 0px",
    opacity: 0.1, // Adjust opacity as needed
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <AnalyticsCard
                title="Total Session Payments"
                icon={<WatchLaterIcon />}
                number={data?.data?.clinicianPayout || 0}
                AvatarColor={theme.colors.warning.main}
                bg={theme.colors.warning.lighter}
                ChartColor={customStyles.background1}
                style={customStyles} // Apply custom styles directly
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                title="Total Amount Paid"
                icon={<SummarizeIcon sx={{ filter: "brightness(0.7)" }} />}
                number={data?.data?.totalPayment || 0}
                AvatarColor={theme.colors.success.main}
                bg={theme.colors.success.lighter}
                ChartColor={customStyles.background2}
                style={customStyles}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                title="Pending Payouts"
                icon={<PersonAddIcon />}
                number={data?.data?.pendingPayout || 0}
                AvatarColor={theme.colors.primary.main}
                bg={theme.colors.primary.lighter}
                ChartColor={customStyles.background1}
                style={customStyles}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box mt="40px" display="flex" gap={2}>
          <Button
            variant={showPayments ? "contained" : "outlined"}
            onClick={() => setshowPayments(true)}
          >
            Session Payments
          </Button>
          <Button
            variant={showPayments ? "outlined" : "contained"}
            onClick={() => setshowPayments(false)}
          >
            Session Payouts
          </Button>
        </Box>
        <Grid item xs={12}>
          {showPayments ? <SessionPayments /> : <PayoutsList />}
        </Grid>
      </Grid>
    </>
  );
}

export default Payments;
