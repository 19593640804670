import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getClinicianProfile, getSessions } from "../../../api/services/api";
import EmptyScreen from "../../../components/EmptyScreen";
import { ViewButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface ModeOption {
  mode: string;
}

export const CompletedSesisons = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [modeValue, setModeValue] = useState<ModeOption[]>([]);
  const [status, setStatus] = useState(["COMPLETED", "REPORT_SUBMITTED"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: profileData } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  const { data, isLoading, isError, error } = useQuery(
    [
      "sessions",
      {
        status: status,
        search: search,
        modeOfConsultation: modeValue
          .map((mode) => mode.mode.toUpperCase())
          .join(","),
        fromDate: startDate,
        toDate: endDate,
        clinicianId: profileData?.data?.id,
        limit: limit,
        offset: limit * page,
      },
    ],
    getSessions
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Session ID",
      dataIndex: "sessionId",
      key: "sessionId",
    },

    {
      title: "Booked Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (colData: any) => {
        return moment(colData).format("DD-MM-YYYY");
      },
    },
    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
      render: (colData: any, row: any) => colData,
    },
    {
      title: "Time slot",
      dataIndex: "time",
      key: "time",
      render: (colData: any, row: any) => (
        <Box display="flex" justifyContent="center">
          {row?.sessionTimeslots?.map((item: any) => (
            <Typography>
              {item?.timeslot?.startAt} - {item?.timeslot?.endAt}
            </Typography>
          ))}
        </Box>
      ),
    },

    {
      title: "Client Name",
      dataIndex: "patientProfile.fullName",
      key: "patientProfile.fullName",
    },

    {
      title: "Mode Of Consultation",
      dataIndex: "consultationMode",
      key: "consultationMode",
      render: (colData: any) => {
        return textCapitalization(colData);
      },
    },
    {
      title: "Speciality",
      dataIndex: "clinician.designation",
      key: "clinician.designation",
    },
    {
      title: "Price",
      dataIndex: "sessionPayment.totalAmount",
      key: "sessionPayment.totalAmount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (colData: any) => {
        return (
          <Button
            sx={{
              py: 1,
              px: 2,
              background:
                colData === "COMPLETED"
                  ? `${theme.colors.success.lighter}`
                  : colData === "REPORT_SUBMITTED"
                  ? `${theme.colors.primary.lighter}`
                  : "",
              color:
                colData === "COMPLETED"
                  ? `${theme.colors.success.main}`
                  : colData === " REPORT_SUBMITTED"
                  ? `${theme.colors.info.main}`
                  : "",
            }}
          >
            {colData?.replace(/_/g, " ")}
          </Button>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <ViewButton
          onClick={() => {
            return navigate(`/sessions/completed-sessions/${row.id}`);
          }}
        />
      ),
    },
  ];

  const modesMenu = [
    {
      mode: "Video",
    },
    {
      mode: "Audio",
    },
    // {
    //   mode: "Home",
    // },
  ];
  return (
    <Box>
      <Box display="flex" gap={1} mb={2} alignItems="center">
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/sessions")}
        />
        <Typography variant="h3">Completed Sessions</Typography>
      </Box>
      <Box display="flex" gap="20px">
        <TextField
          label="Search by Session Id"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          variant="outlined"
          startIcon={<FilterListOutlinedIcon />}
          onClick={handleClick}
        >
          <Typography>Filter</Typography>
        </Button>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(newValue: any) => {
                  setStartDate(moment(new Date(newValue)).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: "230px", mr: 1 }} {...params} />
                )}
              />
              <DatePicker
                label="End Date"
                inputFormat="DD/MM/YYYY"
                value={endDate}
                onChange={(newValue: any) => {
                  setEndDate(moment(new Date(newValue)).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: "230px" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </MenuItem>

          <MenuItem>
            <Box sx={{ width: "100%" }}>
              <Autocomplete
                multiple
                options={modesMenu}
                value={modeValue}
                getOptionLabel={(option: any) => option.mode}
                isOptionEqualToValue={(option, value) =>
                  option.mode === value?.mode
                }
                onChange={(event: any, newValues: any) => {
                  setModeValue(newValues);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} style={{ marginRight: 8 }} />
                    <ListItemText primary={option.mode} />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="Mode of Sessions"
                  />
                )}
              />
            </Box>
          </MenuItem>
        </Menu>
      </Box>
      <Box mt={2}>
        {data?.data.count == 0 ? (
          <EmptyScreen type="Sessions" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Box>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.sessions}
                  rowKey={"id"}
                  columns={columns}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
