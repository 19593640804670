import {
  Box,
  Button,
  Drawer,
  Grid,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../../api/http";
import { getAllResources } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import EmptyScreen from "../../../components/EmptyScreen";
import Loader from "../../../components/Loader";
import ResourcesCard from "../../../components/ResourcesCard";

const Articles = () => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const { data, isLoading } = useQuery(
    [
      "articles",
      {
        type: "ARTICLE",
        limit: limit,
        offset: limit * page,
      },
    ],
    getAllResources
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {" "}
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }} gap={"26px"}>
          <Typography variant="h4">Articles</Typography>
        </Box>
      </Box>
      {data?.data?.count === 0 ? (
        <EmptyScreen type={"articles"} />
      ) : (
        <>
          <Grid container spacing={7}>
            {data?.data?.resources?.map((item: any, index: number) => {
              return (
                <Grid item xs={4} key={index}>
                  <ResourcesCard
                    data={item}
                    image={item?.link}
                    thumbnail={item?.thumbnail}
                    date={item?.createdAt}
                    title={item?.title}
                    desc={item?.description}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TablePagination
              count={data?.data.count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Articles;
