import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { generateReport } from "../../../../api/services/api";

export const GenerateReportForm = ({ toggleDrawer, data }: any) => {
  const queryClient = useQueryClient();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [id, setId] = useState(data?.id);
  console.log("datatatatatatatatatatatatatata",data);

  const { mutate } = useMutation(generateReport, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      toggleDrawer(false);
      queryClient.invalidateQueries("sessions");
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message[0]);
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      medicalRecordNumber: "",
      mainConcern: "",
      procedures: "",
      findings: "",
      behavior: "",
      attention: "",
      eyeContact: "",
      howProcedure: "",
      reinforcement: "",
      generalImpression: "",
      recommendations: "",
      planNextSession: "",
    },
  });

  const onSubmit = (data: any) => {
    const Data = { ...data, date: date };
    const newData = { data: Data };
    const res = {
      ...newData,
      type: "MANUAL",
      sessionId: id,
      date: date,
    };
    mutate(res);
  };

  const procedures = ["Diagnostic", "Treatment", "Assessment", "consultation"];

  const behavior = ["Cooperative", "Uncooperative"];

  const impression = ["Good", "Fair", "Poor"];

  return (
    <Box width="500px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap="20px">
          <TextField
            label="Medical Record Number"
            variant="standard"
            {...register("medicalRecordNumber", {
              required: "Medical Record Number is required",
            })}
            error={Boolean(errors.medicalRecordNumber)}
            helperText={errors.medicalRecordNumber?.message}
          />
          <FormControl component="fieldset" fullWidth={true}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={date}
                  maxDate={moment(new Date())}
                  onChange={(e: any) => {
                    setDate(moment(new Date(e)).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      variant={"standard"}
                      fullWidth={true}
                      required={true}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </FormControl>
          <TextField
            label="Name"
            variant="standard"
            value={data?.patientProfile?.fullName}
          />
          <TextField
            label="Session Number"
            variant="standard"
            value={data?.sessionId}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
              <DatePicker
                label="Date of birth"
                inputFormat="DD/MM/YYYY"
                // maxDate={moment(new Date())}
                value={data?.patientProfile?.dob}
                onChange={() => {}}
                readOnly
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    variant={"standard"}
                    fullWidth={true}
                    required={true}
                  />
                )}  
              />
            </Box>
          </LocalizationProvider>

          <TextField
            label="Age"
            variant="standard"
            value={data?.patientProfile?.age}
          />
          <TextField
            label="City"
            variant="standard"
            value={data?.patientProfile?.city}
          />
          <TextField
            label="Main Concern"
            multiline
            maxRows={3}
            variant="standard"
            {...register("mainConcern", {
              required: "Main Concern is required",
            })}
            error={Boolean(errors.mainConcern)}
            helperText={errors.mainConcern?.message}
          />
          <Controller
            control={control}
            name="procedures"
            render={({ field }) => (
              <Autocomplete
                options={procedures}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Procedures"
                    error={Boolean(errors.procedures)}
                    helperText={errors.procedures?.message}
                    {...register("procedures", {
                      required: "Procedures is required",
                    })}
                    variant="standard"
                  />
                )}
              />
            )}
          />
          <TextField
            label="Findings"
            variant="standard"
            {...register("findings", {
              required: "General Impression is required",
            })}
            error={Boolean(errors.generalImpression)}
            helperText={errors.generalImpression?.message}
          />
          <Controller
            control={control}
            name="behavior"
            render={({ field }) => (
              <Autocomplete
                options={behavior}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Behavior"
                    error={Boolean(errors.behavior)}
                    helperText={errors.behavior?.message}
                    {...register("behavior", {
                      required: "Procedures is required",
                    })}
                    variant="standard"
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="attention"
            render={({ field }) => (
              <Autocomplete
                options={impression}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Attention"
                    error={Boolean(errors.attention)}
                    helperText={errors.attention?.message}
                    {...register("attention", {
                      required: "Procedures is required",
                    })}
                    variant="standard"
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="eyeContact"
            render={({ field }) => (
              <Autocomplete
                options={impression}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Eye Contact"
                    error={Boolean(errors.eyeContact)}
                    helperText={errors.eyeContact?.message}
                    {...register("eyeContact", {
                      required: "Procedures is required",
                    })}
                    variant="standard"
                  />
                )}
              />
            )}
          />
          <TextField
            label="How Procedure was done"
            multiline
            maxRows={3}
            variant="standard"
            {...register("howProcedure", {
              required: "How Procedure was done is required",
            })}
            error={Boolean(errors.howProcedure)}
            helperText={errors.howProcedure?.message}
          />
          <TextField
            label="Reinforcement"
            variant="standard"
            {...register("reinforcement", {
              required: "Reinforcement is required",
            })}
            error={Boolean(errors.reinforcement)}
            helperText={errors.reinforcement?.message}
          />
          <TextField
            label="General Impression"
            variant="standard"
            {...register("generalImpression", {
              required: "General Impression is required",
            })}
            error={Boolean(errors.generalImpression)}
            helperText={errors.generalImpression?.message}
          />
          <TextField
            label="Recommendations"
            multiline
            maxRows={3}
            variant="standard"
            {...register("recommendations", {
              required: "Recommendations is required",
            })}
            error={Boolean(errors.recommendations)}
            helperText={errors.recommendations?.message}
          />
          <TextField
            label="Plan for the next session "
            multiline
            maxRows={3}
            variant="standard"
            {...register("planNextSession", {
              required: "Plan for the next session is required",
            })}
            error={Boolean(errors.planNextSession)}
            helperText={errors.planNextSession?.message}
          />
          <Button type="submit" fullWidth variant="contained">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};
