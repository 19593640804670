import Block from "./images/block.png";
import Clock from "./images/clock.png";
import logo from "./images/logo.png";
import SignupPic from "./images/SignupLayoutPic.png";
import SettingsIcon from "./images/settings.png";
import Notifications from "./images/notifications.png";
import Wallet from "./images/wallet.png";
import Therapist from "./images/therapist.png";
import EmptyImage from "./images/EmptyImage.png";
import ProfileAvatar from "./images/profileAvatar.png";
import Patients from "./images/Patients.png";
import Resources from "./images/Resources.png";
import Youtube from "./images/youtube.png";
import More from "./images/more.png";
import EditIcn from "./images/editIcn.png";
import HideIcon from "./images/eye-crossed.png";
import DeleteIcn from "./images/deleteIcn.png";
import graduateIcn from "./icons/graduate.png";
import mobileIcn from "./icons/mobile.png";
import emailIcn from "./icons/email.png";
import experienceIcn from "./icons/experience.png";
import dobIcn from "./icons/dob.png";
import genderIcn from "./icons/gender.png";
import payoutInc from "./icons/payouts.png";
import reportsInc from "./icons/reports.png";
import serviceIcon from "./images/serviceIcon.png";
import language from "./images/language.png";
import summarize from "./icons/Summarize.png";
import personadd from "./icons/personadd.png";

export const icons = {
  serviceIcon,
  language,
  Block,
  Clock,
  SettingsIcon,
  Notifications,
  Wallet,
  Therapist,
  ProfileAvatar,
  Patients,
  Resources,
  Youtube,
  More,
  EditIcn,
  HideIcon,
  DeleteIcn,
  graduateIcn,
  mobileIcn,
  emailIcn,
  experienceIcn,
  dobIcn,
  genderIcn,
  payoutInc,
  reportsInc,
  summarize,
  personadd,
};

export { logo, SignupPic, EmptyImage };
