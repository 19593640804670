import { Box } from "@mui/material";
import Appbar from "../Appbar";
import SideNav from "./SideNav";
import { Outlet } from "react-router-dom";

const PrimaryLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Appbar />
      <SideNav />
      <Box ml={42} mt={10} flex={1} mr={3.5}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default PrimaryLayout;
