import { Box, Card, Divider, Drawer, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  getClinicianProfile,
  getSessionPackage,
} from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import textCapitalization from "../../../components/textCapitalization";
import { EditProfileForm } from "./EditProfileForm";
import { SideProfileInfo } from "./SideProfileInfo";

export const SessionPackage = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleProfileClick = () => {
    setOpen(true);
  };

  const { data, isLoading } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );
  console.log(data, "Check2");

  let item = data?.data;

  const { data: sessionPackage, isLoading: sessionPackageLoading } = useQuery(
    "session-package",
    getSessionPackage
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Card>
            <Box
              py={1}
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontSize: "20px" }}>
                Session Package Details
              </Typography>
            </Box>
            <Divider />
            {/* {data?.data?.services?.map((item: any) => ( */}
            <Box
              marginLeft={2}
              p={1}
              display="flex"
              justifyContent="space-between"
              flexDirection={"column"}
            >
              <NameBox
                title={"Audio Call Consultation Fee"}
                detail={data?.data?.audioModePrice}
              />
              <NameBox
                title={"Video Call Consultation Fee"}
                detail={data?.data?.videoModePrice}
              />
            </Box>
            {/* ))} */}
          </Card>
        </Grid>
        <Grid item xs={3}>
          <SideProfileInfo
            img={item?.imageUrl}
            name={item?.user?.fullName}
            location={item?.location}
            onClick={() => setOpen(true)}
            designation={item?.designation}
            number={item?.user?.mobileNumber}
            email={item?.user?.email}
            experience={item?.experience}
            dob={moment(item?.dob).format("DD-MM-YYYY")}
            gender={textCapitalization(item?.gender || "")}
            languages={item?.languagesKnown}
            services={item?.services}
          />
        </Grid>
      </Grid>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography>Edit Profile</Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <EditProfileForm
            prevData={item}
            toggleDrawer={(e: any) => toggleDrawer(e)}
          />
        </StyledDrawerContent>
      </Drawer>
    </Box>
  );
};

const NameBox = ({ title, detail }: any) => {
  return (
    <Box pb={2}>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {detail}
      </Typography>
    </Box>
  );
};
