import { Avatar, Box, Button, Card, Divider, Typography } from "@mui/material";
import { icons } from "../../../assets";

export const SideProfileInfo = ({
  img,
  name,
  location,
  onClick,
  designation,
  number,
  email,
  experience,
  dob,
  gender,
  languages,
  services,
}: any) => {
  return (
    <>
      <Card
        sx={{
          padding: "40px 30px 20px 30px",
        }}
      >
        <Box sx={{ textAlign: "center", margin: "auto", width: "50%" }}>
          <Avatar
            variant="rounded"
            sx={{ height: "142px", width: "142px", fontSize: "100px" }}
            src={img}
            alt={name}
          />
          <Typography variant="h3" m="15px 0 10px 0">
            {name}
          </Typography>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">{location}</Typography>
            <Button
              sx={{ marginTop: "10px" }}
              variant="contained"
              onClick={onClick}
            >
              {" "}
              Edit Profile
            </Button>
          </Box>
        </Box>
        <Box py="20px">
          <Divider />
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.graduateIcn} alt="graduate" height="16px" />
            <Typography ml={1}>{designation}</Typography>
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.mobileIcn} alt="mobile" height="16px" />
            <Typography ml={1}>{number}</Typography>
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.emailIcn} alt="email" height="16px" />
            <Typography ml={1}>{email}</Typography>
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.experienceIcn} alt="experience" height="16px" />
            <Typography ml={1}>{experience} Year Experience</Typography>
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.dobIcn} alt="dob" height="16px" />
            <Typography ml={1}>{dob}</Typography>
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.genderIcn} alt="gender" height="16px" />
            <Typography ml={1}>{gender}</Typography>
          </Box>
          <Box display="flex" mt={2} alignItems="center">
            <img src={icons.language} alt="gender" height="16px" />
            <Typography ml={1}>{languages}</Typography>
          </Box>
          <Box display="flex" mt={2} gap={1} alignItems="center">
            <img src={icons.serviceIcon} alt="gender" height="16px" />
            {services?.map((item: any, index: number) => (
              <Box display="flex" key={index}>
                <Typography>{item?.title}</Typography>
                {services.length !== index + 1 && <Typography>,</Typography>}
              </Box>
            ))}
          </Box>
        </Box>
      </Card>
    </>
  );
};
