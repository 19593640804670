import { Box, IconButton, TablePagination, TextField } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";

import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import {
  getClinicianPayouts,
  getClinicianProfile,
} from "../../../api/services/api";
import EmptyScreen from "../../../components/EmptyScreen";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";

export const PayoutsList = () => {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(0);

  const { data: clinicianData } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  const { data, isLoading } = useQuery(
    [
      "payouts",
      {
        clinicianId: clinicianData?.data?.id,
        limit: limit,
        offset: limit * page,
      },
    ],
    getClinicianPayouts,
    {
      enabled: Boolean(clinicianData?.data?.id),
    }
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Payment Reference ID",
      dataIndex: "paymentReferenceId",
      key: "paymentReferenceId",
    },

    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (colData: any) => {
        return moment(colData).format("DD-MM-YYYY");
      },
    },
    {
      title: "Payment",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
  ];

  return (
    <Box>
      <Box>
        <TextField
          label="Search by Payment Reference ID"
          sx={{ width: "450px", padding: "0px", my: 2 }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {data?.data?.count == 0 ? (
          <EmptyScreen type="Payouts" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Table
                  headAlign={"left"}
                  tableAlign={"left"}
                  key={"payouts"}
                  dataSource={data?.data?.payouts}
                  rowKey={"id"}
                  columns={columns}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
