import { Box } from "@mui/material";
import AgoraUIKit, { PropsInterface } from "agora-react-uikit";

import AgoraRTC from "agora-rtc-sdk-ng";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getSessionById, postDuration } from "../../../api/services/api";
import { Timer } from "../../../components/Timer";
import CustomAgoraUIKit from "./customUiKit";

const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

export const VideoCall = ({ token, channel, setToken, setChannel }: any) => {
  const { id } = useParams();
  const rtcEngineRef = useRef<any>(null);
  const agoraUIKitRef = useRef<any>(null);
  const navigate = useNavigate();
  const [videocall, setVideocall] = useState(true);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [userLeft, setUserLeft] = useState(false);
  const [userJoin, setUserJoin] = useState(false);
  const [userEndCall, setUserEndCall] = useState(true);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);

  const { mutate: durationMutate } = useMutation(postDuration);

  const { mutate: newDuration } = useMutation(postDuration, {
    onSuccess: () => {
      setVideocall(false);
      setToken(null);
      setChannel(null);
      navigate("/sessions");
    },
  });

  const { data, isLoading } = useQuery(["sessionDetail", id], getSessionById, {
    enabled: !!id,
  });
  console.log(data, "Check1");

  const newFunc = () => {
    const res = {
      sessionId: +id!,
      duration: Math.floor(totalSeconds / 60),
      userType: "CLINICIAN",
    };
    newDuration(res);
  };

  const handleEndCall = () => {
    if (userEndCall === true) {
      newFunc();
    }
    setVideocall(false);
    setToken(null);
    setChannel(null);
    window.location.reload();
    // navigate(`/sessions/upcoming-sessions/${id}`);
  };

  useEffect(() => {
    if (userJoin === true) {
      const timer = setTimeout(() => {
        setTotalSeconds(totalSeconds + 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  });

  const handleJoinCall = () => {
    setUserJoin(true);
  };

  const handleJoinAudioCall = async () => {
    try {
      setUserJoin(true);
      // Initialize only the audio track
      const audioTrack: any = await AgoraRTC.createMicrophoneAudioTrack();
      setLocalAudioTrack(audioTrack);

      await client.join(
        "968ae33c48f744f1bcc1b7f120348c74",
        channel,
        token,
        null
      );
      await client.publish([audioTrack]);
      setVideocall(true);
    } catch (error: any) {
      console.error("Failed to join the session", error);
      if (error.code === "OPERATION_ABORTED") {
        // Handle the specific error case
        console.error(
          "Operation was aborted. This could be due to a token issue or network instability."
        );
      }
    }
  };

  useEffect(() => {
    if (userLeft === true) {
      const res = {
        sessionId: +id!,
        duration: Math.floor(totalSeconds / 60),
        userType: "CLINICIAN",
      };
      durationMutate(res);
      setUserJoin(false);
    }
    setUserLeft(false);
  }, [userLeft, totalSeconds, id, durationMutate]);

  const handleLeft = () => {
    setUserLeft(true);
    setUserEndCall(false);
  };

  // useEffect(() => {
  //   if (userLeft === false) {
  //     const handleWindowClose = (event: any) => {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       const res = {
  //         sessionId: +id!,
  //         duration: Math.floor(totalSeconds / 60),
  //         userType: "CLINICIAN",
  //       };
  //       durationMutate(res);
  //     };
  //     const handleWindowUnload = () => {
  //       alert("User has left the page.");
  //       console.log("User has left the page.");
  //       const res = {
  //         sessionId: +id!,
  //         duration: Math.floor(totalSeconds / 60),
  //         userType: "CLINICIAN",
  //       };
  //       durationMutate(res);
  //     };
  //     window.addEventListener("beforeunload", handleWindowClose);
  //     window.addEventListener("unload", handleWindowUnload);
  //     return () => {
  //       window.removeEventListener("beforeunload", handleWindowClose);
  //       window.addEventListener("unload", handleWindowUnload);
  //     };
  //   }
  // }, []);

  const videoProps: PropsInterface = {
    rtcProps: {
      appId: "968ae33c48f744f1bcc1b7f120348c74",
      channel: channel,
      token: token,
      enableScreensharing: true,
    },
    rtmProps: {
      username: data?.data?.patienProfile?.fullName,
      displayUsername: true,
    },
    callbacks: {
      EndCall: () => handleEndCall(),
      "user-joined": () => handleJoinCall(),
      "user-left": () => handleLeft(),
    },
  };

  const audioProps: PropsInterface = {
    rtcProps: {
      appId: "968ae33c48f744f1bcc1b7f120348c74",
      channel: channel,
      token: token,
    },
    rtmProps: {
      username: data?.data?.patienProfile?.fullName,
      displayUsername: true,
    },
    callbacks: {
      EndCall: () => handleEndCall(),
      "user-joined": () => handleJoinCall(),
      "user-left": () => handleLeft(),
    },
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "85vh",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Box position="relative" top="20px" zIndex={1} left={140}>
          <Timer sessionUrl={id} />
        </Box>
        {videocall &&
          (data?.data?.consultationMode === "AUDIO" ? (
            <CustomAgoraUIKit
              styleProps={{
                localBtnContainer: {
                  backgroundColor: "#000",
                },
              }}
              rtmProps={audioProps.rtmProps}
              rtcProps={{ ...audioProps.rtcProps, cameraOn: false }}
              callbacks={audioProps.callbacks}
            />
          ) : (
            <AgoraUIKit
              styleProps={{
                localBtnContainer: {
                  backgroundColor: "#000",
                },
              }}
              rtmProps={videoProps.rtmProps}
              rtcProps={{
                ...videoProps.rtcProps,
              }}
              callbacks={videoProps.callbacks}
            />
          ))}
      </Box>
    </>
  );
};
