import {
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { getClinicianProfile } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import { EditProfileForm } from "./EditProfileForm";
import { SideProfileInfo } from "./SideProfileInfo";

export const BasicInformation = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const { data, isLoading } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  let item = data?.data;
  let Gender =
    item?.gender.slice(0, 1).toUpperCase() +
    item?.gender.slice(1).toLowerCase();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Card>
            <Box
              py={1}
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontSize: "20px" }}>
                Basic Information
              </Typography>
              <Button onClick={() => setOpen(true)} variant="outlined">
                Edit
              </Button>
            </Box>
            <Divider />
            <Grid p={2} container>
              <Grid item xs={6}>
                <NameBox title="Name" detail={item?.user?.fullName} />
                <NameBox
                  title="Date of Birth"
                  detail={moment(item?.dob).format("DD-MM-YYYY")}
                />
                <NameBox
                  title="Mobile Number"
                  detail={item?.user?.mobileNumber}
                />
                <NameBox title="Email Address" detail={item?.user?.email} />
                <NameBox title="Bio" detail={item?.bio} />
              </Grid>
              <Grid item xs={6}>
                <NameBox title="Gender" detail={Gender} />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <SideProfileInfo
            img={item?.imageUrl}
            name={item?.user?.fullName}
            location={item?.location}
            onClick={() => setOpen(true)}
            designation={item?.designation}
            number={item?.user?.mobileNumber}
            email={item?.user?.email}
            experience={item?.experience}
            dob={moment(item?.dob).format("DD-MM-YYYY")}
            gender={Gender}
            languages={item?.languagesKnown}
            services={item?.services}
          />
        </Grid>
      </Grid>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography>Edit Profile</Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <EditProfileForm
            prevData={item}
            toggleDrawer={(e: any) => toggleDrawer(e)}
          />
        </StyledDrawerContent>
      </Drawer>
    </Box>
  );
};

const NameBox = ({ title, detail }: any) => {
  return (
    <Box pb={2}>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {detail}
      </Typography>
    </Box>
  );
};
