import EastIcon from "@mui/icons-material/East";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Icon,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getClinicianProfile, getSessions } from "../../../../api/services/api";
import Loader from "../../../../components/Loader";

export const PendingSessionCard = ({ filterData, setFilterData }: any) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [search, setSearch] = useState("");

  const { data: profileData } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );

  const { data, isLoading } = useQuery(
    [
      "sessions",
      {
        search: search,
        clinicianId: profileData?.data?.id,
      },
    ],
    getSessions
  );

  if (isLoading) {
    <Loader />;
  }

  return (
    <>
      <TextField
        label="Search by Session Id"
        sx={{ mb: 2, width: "100%" }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {data?.data.sessions?.map((item: any, index: number) => (
        <Box
          key={index}
          p={2}
          mb={1.5}
          border={
            item?.id == filterData
              ? `1px solid ${theme.colors.primary.main}`
              : "1px solid rgba(34, 34, 34, 0.08)"
          }
          borderRadius="5px"
          onClick={() => {
            setFilterData(item?.id);
            navigate(`/sessions/upcoming-sessions/${item?.id}`);
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">{item?.sessionId}</Typography>
            <Box
              sx={{
                backgroundColor:
                  item?.status === "PENDING"
                    ? `${theme.colors.warning.lighter}`
                    : item?.status === "APPROVED"
                    ? `${theme.colors.success.lighter}`
                    : item?.status === "COMPLETED"
                    ? `${theme.colors.secondary.lighter}`
                    : item?.status === "REPORT_SUBMITTED"
                    ? `${theme.colors.info.lighter}`
                    : item?.status === "CANCELLED"
                    ? `${theme.colors.error.lighter}`
                    : item?.status === "REJECTED"
                    ? `${theme.colors.error.lighter}`
                    : item?.status === "PAID"
                    ? `${theme.colors.primary.lighter}`
                    : item?.status === "STARTED"
                    ? `${theme.colors.info.lighter}`
                    : item?.status === "NEW_CLINICIAN_ASSIGNED"
                    ? `${theme.colors.warning.lighter}`
                    : "",
                color:
                  item?.status === "PENDING"
                    ? `${theme.colors.warning.main}`
                    : item?.status === "APPROVED"
                    ? `${theme.colors.success.main}`
                    : item?.status === "COMPLETED"
                    ? `${theme.colors.secondary.main}`
                    : item?.status === "REPORT_SUBMITTED"
                    ? `${theme.colors.info.main}`
                    : item?.status === "CANCELLED"
                    ? `${theme.colors.error.main}`
                    : item?.status === "REJECTED"
                    ? `${theme.colors.error.main}`
                    : item?.status === "PAID"
                    ? `${theme.colors.primary.main}`
                    : item?.status === "STARTED"
                    ? `${theme.colors.info.main}`
                    : item?.status === "NEW_CLINICIAN_ASSIGNED"
                    ? `${theme.colors.warning.main}`
                    : "",
                px: 1.5,
                py: 1,
                borderRadius: "5px",
              }}
            >
              <Typography>{item?.status?.replace(/_/g, " ")}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1} pt={1}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"center"}
              p={1}
              maxWidth="30px"
              alignItems="center"
              sx={{ backgroundColor: "#F0F0F0", borderRadius: "10px" }}
            >
              <Typography>{moment(item?.date).format("DD")}</Typography>
              <Typography>{moment(item?.date).format("MMM")}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="caption" sx={{ fontSize: "12px" }}>
                Time slot
              </Typography>
              {item?.sessionTimeslots?.map((time: any) => (
                <Typography>
                  {time?.timeslot?.startAt}-{time?.timeslot?.endAt}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={2}
            >
              <Typography variant="caption" sx={{ fontSize: "12px" }}>
                Mode of consultation
              </Typography>
              <Typography>
                {item?.consultationMode.slice(0, 1).toUpperCase() +
                  item?.consultationMode.slice(1).toLowerCase()}
              </Typography>
            </Box>
            <Icon sx={{ marginTop: "20px" }}>
              <EastIcon />
            </Icon>
          </Box>
        </Box>
      ))}
    </>
  );
};
