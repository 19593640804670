import { icons } from "../../../assets";

export const toggleMenuItems = [
  {
    title: "Reports",
    path: "/reports",
    icon: icons.Block,
  },
  {
    title: "Master Data",
    path: "/master-data",
    icon: icons.Block,
  },
  {
    title: "Source Data",
    path: "/source-data",
    icon: icons.Block,
  },
];
export const menuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: icons.Block,
  },
  {
    title: "My Sessions",
    path: "/sessions",
    icon: icons.Clock,
  },
  {
    title: "My Clients",
    path: "/patients",
    icon: icons.Patients,
  },
  {
    title: "My Profile",
    path: "/profile",
    icon: icons.Therapist,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: icons.reportsInc,
  },
  {
    title: "Payments",
    path: "/payments",
    icon: icons.payoutInc,
  },

  {
    title: "Resources",
    path: "/resources",
    icon: icons.Resources,
  },
];

export const headerTitle = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Incentive Programs",
    path: "/incentive-programs",
  },
  {
    title: "Payouts",
    path: "/payouts",
  },
  {
    title: "Employees",
    path: "/employees",
  },
  {
    title: "Disputes",
    path: "/disputes",
  },
  {
    title: "Reports",
    path: "/reports",
  },
  {
    title: "Master Data",
    path: "/master-data",
  },
  {
    title: "Source Data",
    path: "/source-data",
  },
];
