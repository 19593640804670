import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getMedicalRecords, getSessionById } from "../../../api/services/api";
import Loader from "../../../components/Loader";
import { PendingSessionCard } from "../Sessions/session view/PendingSessionCard";
import { SessionDetailCard } from "../Sessions/session view/SessionDetailCard";

export const PatientSessionDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [patientProfileId, setpatientProfileId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { id } = useParams();

  const [activeStep, setActiveStep] = React.useState(-1);
  const [filterData, setFilterData] = useState(id);

  const { data, isLoading, isError, error } = useQuery(
    ["sessions", filterData],
    getSessionById
  );

  const { data: getMedicalRecordsData } = useQuery(
    [
      "medical-record",
      {
        patientProfileId: patientProfileId,
      },
    ],
    getMedicalRecords,
    {
      enabled: Boolean(patientProfileId),
    }
  );

  const handleReports = (id: any) => {
    setpatientProfileId(id);
    setOpenDialog(true);
  };

  const steps = [
    {
      label: "Session Pending",
    },
    {
      label: "Session Booked",
    },
    {
      label: "Session Approved",
    },
    {
      label: "Payment completed",
    },
    {
      label: "Session Started",
    },
    {
      label: "Session completed",
    },
    {
      label: "Report Sent",
    },
  ];

  function getStepsStatus(status: string) {
    switch (status) {
      case "PENDING":
        setActiveStep(0);
        break;
      case "BOOKED":
        setActiveStep(1);
        break;
      case "APPROVED":
        setActiveStep(2);
        break;
      case "PAID":
        setActiveStep(3);
        break;
      case "STARTED":
        setActiveStep(4);
        break;
      case "COMPLETED":
        setActiveStep(5);
        break;
      case "SENT":
        setActiveStep(6);
        break;

      default:
        setActiveStep(-1);
        break;
    }
  }

  useEffect(() => {
    getStepsStatus(data?.data?.status);
  });

  let mode =
    data?.data?.consultationMode.slice(0, 1).toUpperCase() +
    data?.data?.consultationMode.slice(1).toLowerCase();

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Typography variant="h3">Session Details</Typography>
      </Box>

      <Grid my="10px" container spacing={2}>
        <Grid item lg={4} md={5}>
          <Card sx={{ pt: "25px" }}>
            <Typography pb={2} px={2} variant="h4">
              Other Sessions
            </Typography>
            <Divider />
            <Box p={2} overflow="auto" maxHeight="700px" className="scrollBox">
              <PendingSessionCard
                filterData={id}
                setFilterData={setFilterData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={5}>
          <Card sx={{ pt: "25px" }}>
            <Typography pb={2} px={2} variant="h4">
              Sessions Details
            </Typography>
            <Divider />
            {isLoading ? (
              <Loader />
            ) : (
              <SessionDetailCard
                patientView={true}
                key={id}
                statusData={data}
                sessionId={data?.data?.sessionId}
                speciality={data?.data?.clinician?.designation}
                modeOfConsultation={mode}
                bookedDate={moment(data?.data?.createdAt).format("DD-MM-YYYY")}
                sessionDate={moment(data?.data?.date).format("DD")}
                sessionMonth={moment(data?.data?.date).format("MMM")}
                startTime={data?.data?.startAt}
                endTime={data?.data?.endAt}
                timeSlots={data?.data?.sessionTimeslots}
                description={data?.data?.description}
                doctorImage={data?.data?.clinician?.imageUrl}
                doctorName={data?.data?.clinician?.user?.fullName}
                doctorExperience={data?.data?.clinician?.experience}
                doctorSpeciality={data?.data?.clinician?.designation}
                patientImage={data?.data?.patient?.image}
                patientName={data?.data?.patient?.user?.fullName}
                patientNumber={data?.data?.patient?.user?.mobileNumber}
                patientEmail={data?.data?.patient?.user?.email || "N/A"}
                patientProfileImage={data?.data?.patientProfile?.image}
                PatientProfileName={data?.data?.patientProfile?.fullName}
                PatientProfileAge={data?.data?.patientProfile?.age}
                PatientProfileGender={data?.data?.patientProfile?.gender}
                PatientLandmark={data?.data?.patientAddress?.landmark}
                patientAddress1={
                  data?.data?.patientAddress?.addressLine1 || "N/A"
                }
                patientAddress2={
                  data?.data?.patientAddress?.addressLine2 || "N/A"
                }
                PatientCity={data?.data?.patientAddress?.city}
                consultationFee={data?.data?.consultationFee}
                startSessionClick={""}
                handleComplete={""}
                handleReportClick={() =>
                  handleReports(data?.data?.patientProfile?.id)
                }
              />
            )}
          </Card>
        </Grid>
        {/* <Grid item lg={3.5}>
          <Card sx={{ pt: "25px" }}>
            <Typography pb={2} px={2} variant="h4">
              Timeline
            </Typography>
            <Divider />
            <Box maxWidth="500px" px={3}>
              <Stepper
                sx={{ background: "white" }}
                activeStep={activeStep}
                orientation="vertical"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>
                      <Typography variant="h5">{step.label}</Typography>
                      <Typography>
                        {moment(
                          data?.data?.sessionStatuses[index]?.createdAt
                        ).format("DD-MM-YYYY hh:mm:ss")}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Card>
        </Grid> */}
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ fontSize: "20px" }}>Medical Records</DialogTitle>
        <DialogContent sx={{ maxWidth: "550px" }} dividers>
          <Box width="100%" height="100%">
            {getMedicalRecordsData?.data?.data?.length === 0 ? (
              <Typography variant="h5" sx={{ opacity: "0.7" }}>
                No Medical Record Found...{" "}
              </Typography>
            ) : (
              <>
                {getMedicalRecordsData?.data?.data?.map(
                  (reports: any, index: number) => (
                    <>
                      <Box>
                        <Box maxHeight="500px">
                          <List sx={{ width: "500px", px: 1 }} key={index}>
                            <ListItem
                              secondaryAction={
                                <Box>
                                  <a
                                    href={reports?.fileUrl}
                                    download
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button variant="outlined">
                                      <DownloadIcon sx={{ pr: 1 }} />
                                      Download Report
                                    </Button>
                                  </a>
                                </Box>
                              }
                            >
                              <Box
                                sx={{
                                  backgroundColor: theme.colors.primary.lighter,
                                  p: 1.5,
                                  borderRadius: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  mr: 1,
                                }}
                              >
                                <Icon sx={{ color: theme.colors.primary.main }}>
                                  <DescriptionOutlinedIcon />
                                </Icon>
                              </Box>
                              <ListItemText>Report {index + 1}</ListItemText>
                            </ListItem>
                          </List>
                        </Box>
                      </Box>
                    </>
                  )
                )}
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
