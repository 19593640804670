import AgoraUIKit from "agora-react-uikit";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getClinicianProfile } from "../../../api/services/api";
const CustomAgoraUIKit = ({ rtcProps, rtmProps, callbacks, styleProps }) => {
  const cleanUpDivs = () => {
    const targetDivs = document.querySelectorAll(
      'div._3Sxu7[style*="overflow: hidden scroll; display: flex; flex: 1 1 0%; flex-direction: column;"]'
    );

    console.log(targetDivs, "Target Divs");

    targetDivs.forEach((div) => {
      div.parentNode.removeChild(div);
    });
  };
  const { data, isLoading } = useQuery(
    ["clinician-profile"],
    getClinicianProfile
  );
  const disableVideo = () => {
    console.log("Attempting to disable the video...");

    const videoElements = document.querySelectorAll(
      '[id^="video_track-cam"], [id^="video_track-video"]'
    );
    console.log(videoElements, "Video");

    videoElements.forEach((videoElement) => {
      videoElement.muted = true;
      console.log(`Video (${videoElement.id}) has been muted.`);
      videoElement.pause();

      const videoContainer = videoElement.closest(
        '[id^="agora-video-player-track-cam"],[id^="agora-video-player-track-video"]'
      );
      if (videoContainer) {
        console.log(videoContainer, "test2");

        const imageElement = document.createElement("img");
        imageElement.src = data?.data?.imageUrl;
        imageElement.style = videoElement.style.cssText;

        videoContainer.innerHTML = "";
        videoContainer.appendChild(imageElement);
        console.log(
          `Video (${videoElement.id}) has been replaced with a static image.`
        );
      }
    });
  };

  const removeCameraButton = () => {
    const mainButtonContainer = document.querySelector(
      'div[style*="background-color: rgb(0, 0, 0); width: 100%; height: 70px; z-index: 10; display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;"]'
    );
    console.log(mainButtonContainer, "This is Main");
    if (mainButtonContainer) {
      const cameraButtonDiv = mainButtonContainer.querySelector(
        'svg polygon[points="23 7 16 12 23 17 23 7"]'
      )?.parentNode?.parentNode?.parentNode;
      if (cameraButtonDiv) {
        mainButtonContainer.removeChild(cameraButtonDiv);
        console.log("Camera icon button has been removed.");
      } else {
        console.log("Camera button not found.");
      }
    } else {
      console.log("Main button container not found.");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Override getUserMedia to prevent camera access
      // navigator.mediaDevices.getUserMedia = async (constraints) => {
      //   if (constraints.video) {
      //     console.log("Blocking camera access.");
      //     return Promise.reject(new Error("Camera access is blocked."));
      //   }
      //   return Promise.resolve(new MediaStream());
      // };
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            disableVideo();
            cleanUpDivs();
            removeCameraButton();
          }
        }
      });
      observer.observe(document.body, { childList: true, subtree: true });
      disableVideo();
      cleanUpDivs();
      removeCameraButton();
    }
  }, []);

  return (
    <AgoraUIKit
      rtcProps={rtcProps}
      rtmProps={rtmProps}
      callbacks={callbacks}
      styleProps={styleProps}
    />
  );
};

export default CustomAgoraUIKit;
