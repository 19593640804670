//@ts-ignore
import loadable from "@loadable/component";
import PrimaryLayout from "../layout/adminLayout/primaryLayout";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import { useEffect } from "react";
import Patients from "../pages/admin/Patients/Patients";
import Resources from "../pages/admin/Resources";
import Videos from "../pages/admin/Resources/Videos";
import Articles from "../pages/admin/Resources/Articles";

import PrivacyPolicy from "../pages/PrivacyPolicy";

import { Reports } from "../pages/admin/Reports/Reports";

import { Session } from "../pages/admin/Sessions/Session";

import Payments from "../pages/admin/Payments";
import { Profile } from "../pages/admin/Profile";
import { SessionPackage } from "../pages/admin/Profile/SessionPackage";
import { PaymentDetails } from "../pages/admin/Profile/PaymentDetails";

import { BasicInformation } from "../pages/admin/Profile/BasicInformation";
import { UpcomingSessions } from "../pages/admin/Sessions/UpcomingSessions";
import { CompletedSesisons } from "../pages/admin/Sessions/CompletedSesisons";
import { CancelledSessions } from "../pages/admin/Sessions/CancelledSessions";
import { SessionView } from "../pages/admin/Sessions/session view/SessionView";
import SlotManagement from "../pages/admin/Profile/SlotManagement";
import { PatientSession } from "../pages/admin/Patients/PatientSession";
import { PatientSessionDetail } from "../pages/admin/Patients/PatientSessionDetail";

import { VideoCall } from "../pages/admin/VideoCall";
import PatientProfile from "../pages/admin/Patients/PatientProfile";

const Signup = loadable(() => import("../pages/SignUp"));
const Login = loadable(() => import("../pages/Login"));
const ForgotPassword = loadable(() => import("../pages/ForgotPassword"));
const OTPVerification = loadable(() => import("../pages/OTPVerification"));

const AdminRoutesContainer = () => {
  const navigate = useNavigate();

  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    const authenticate = localStorage.getItem("token") ? true : false;
    !authenticate && navigate("/login", { replace: true });
  }, []);

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="otp-verification" element={<OTPVerification />} />
      <Route path="/" element={<PrimaryLayout />}>
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="sessions">
          <Route path="" element={<Session />} />
          <Route path="upcoming-sessions">
            <Route path="" element={<UpcomingSessions />} />
            <Route path="video-call" element={<VideoCall />} />
            <Route path=":id" element={<SessionView />} />
          </Route>
          <Route path="completed-sessions">
            <Route path="" element={<CompletedSesisons />} />
            <Route path=":id" element={<SessionView />} />
          </Route>
          <Route path="cancelled-sessions">
            <Route path="" element={<CancelledSessions />} />
            <Route path=":id" element={<SessionView />} />
          </Route>
        </Route>
        <Route path="patients" element={<Patients />} />
        <Route
          path="patients/patient-profile/:id"
          element={<PatientProfile />}
        />
        <Route
          path="patients/patient-session/:id"
          element={<PatientSession />}
        />
        <Route
          path="patients/patient-session/:id/session-details/:id"
          element={<PatientSessionDetail />}
        />
        <Route path="profile" element={<Profile />}>
          <Route path="" element={<BasicInformation />} />
          <Route path="session-package" element={<SessionPackage />} />
          <Route path="payment-details" element={<PaymentDetails />} />
          <Route path="slot-management" element={<SlotManagement />} />
        </Route>
        <Route path="reports/:id" element={<Reports />} />
        <Route path="reports" element={<Reports />} />
        <Route path="payments" element={<Payments />} />
        <Route path="resources" element={<Resources />}>
          <Route path="" element={<Videos />} />
          <Route path="articles" element={<Articles />} />
        </Route>
      </Route>
      <Route path="privacy-policy" element={<PrivacyPolicy />} />

    </Routes>
  );
};

export default AdminRoutesContainer;
