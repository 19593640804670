import { http } from "../http";

export const getPatients = ({ queryKey }: any) => {
  return http.get(`/clinicians/${queryKey[1]}/patients`, {
    params: {
      ...queryKey[2],
    },
  });
};

export const getPatientById = ({ queryKey }: any) => {
  return http.get(`/patients/patient-profile/${queryKey[1]}`);
};

export const getClinicianProfile = () => {
  return http.get("/clinicians/profile");
};

export const getSessions = ({ queryKey }: any) => {
  return http.get("/sessions", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getSessionById = ({ queryKey }: any) => {
  return http.get(`/sessions/${queryKey[1]}`);
};

export const joinSession = ({ queryKey }: any) => {
  return http.get(`/sessions/join`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getDashboardData = ({ queryKey }: any) => {
  return http.get(`/dashboard/clinicians/${queryKey[1]}`);
};

export const getSessionData = ({ queryKey }: any) => {
  return http.get(`/dashboard/clinicians/${queryKey[1]}/my-sessions`);
};

export const getClinicianTimeSlots = async ({ queryKey }: any) => {
  return await http.get(`clinician-time-slot/clinicians/${queryKey[1]}/slots`);
};

export const updateTimeSlots = (body: any) => {
  return http.patch("/clinician-time-slot", body);
};

export const getReports = ({ queryKey }: any) => {
  return http.get(`/reports`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getReportsById = ({ queryKey }: any) => {
  return http.get(`/reports/${queryKey[1]}`);
};

export const getMedicalRecords = ({ queryKey }: any) => {
  return http.get(`/medical-records`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getClinicianPayouts = ({ queryKey }: any) => {
  return http.get("/payouts", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getPayoutStatistics = ({ queryKey }: any) => {
  return http.get(`/payouts/clinicians/${queryKey[1]}`);
};

export const updateClinicianProfile = ({ body, id }: any) => {
  return http.patch(`/clinicians/profile/${id}`, body);
};

export const updateClinicianSession = ({ body, id }: any) => {
  return http.patch(`/sessions/update-session-clinician/${id}`, body);
};

export const getSessionPackage = () => {
  return http.get(`/mode-of-consultation`);
};

export const generateToken = (body: any) => {
  return http.post("/sessions/token", body);
};

export function getAllResources({ queryKey }: any) {
  return http.get(`/resources/`, {
    params: {
      ...queryKey[1],
    },
  });
}

export function getAllServices({ queryKey }: any) {
  return http.get(`/services/`, {
    params: {
      ...queryKey[1],
    },
  });
}

export const updateSession = ({ id, body }: any) => {
  return http.patch(`sessions/update-session-status/${id}`, body);
};

export const generateReport = (body: any) => {
  return http.post(`reports`, body);
};

export const postDuration = (body: any) => {
  return http.post(`duration`, body);
};
